async function readEntriesPromise(directoryReader) {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (error) {
    console.warn(error);
    return [];
  }
}

async function readDirectoryEntries(directoryEntry) {
  const directoryReader = directoryEntry.createReader();
  const directoryEntries = [];

  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    directoryEntries.push(...readEntries);

    // eslint-disable-next-line no-await-in-loop
    readEntries = await readEntriesPromise(directoryReader);
  }

  return directoryEntries;
}

async function getFilesFromDataTransfer(dataTransfer) {
  const items = dataTransfer.items;

  const queue = [];
  for (let i = 0; i < items.length; i++) {
    queue.push(items[i].webkitGetAsEntry());
  }

  const fileEntries = [];
  while (queue.length > 0) {
    const entry = queue.shift();
    if (entry.isFile) {
      fileEntries.push(entry);
    } else {
      // eslint-disable-next-line no-await-in-loop
      queue.push(...(await readDirectoryEntries(entry)));
    }
  }

  const filePromises = fileEntries.map(
    fileEntry =>
      new Promise(accept => {
        fileEntry.file(file => {
          file.slidesliveRelativePath = fileEntry.fullPath.replace(/^\//, '');
          accept(file);
        });
      }),
  );

  return Promise.all(filePromises);
}

export default getFilesFromDataTransfer;
