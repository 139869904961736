function formatSize(size, units = 'B') {
  if (size >= 1000 * 1000 * 1000) {
    return `${Math.round(size / (1000 * 1000 * 1000), -2)} G${units}`;
  }

  if (size >= 1000 * 1000) {
    return `${Math.round(size / (1000 * 1000), -2)} M${units}`;
  }

  if (size >= 1000) {
    return `${Math.round(size / 1000, -2)} K${units}`;
  }

  return `${Math.round(size, -2)} ${units}`;
}

export default formatSize;
