export default {
  primary: {
    DEFAULT: '#2BCDA8',
    hover: '#51DABB',
    focus: '#80FFE2',
  },
  red: {
    DEFAULT: '#F0353D',
    light: '#FFB0B0',
    dark: '#502B2B',
  },
  orange: {
    DEFAULT: '#FF9900',
  },
  green: {
    DEFAULT: '#63CF51',
    light: '#42E4BF',
    dark: '#2d3e3a',
  },
  purple: {
    DEFAULT: '#B69ADB',
    dark: '#5a526c',
    subtle: '#403e89',
  },
  lightgrey: '#f9f9fa',
  grey: '#545454',
  semidark: '#343039',
  dark: '#1D1D1D',
  gradient: {
    from: '#3D3580',
    to: '#593370',
  },
  state: {
    succ: '#63CF51',
    err: '#F0353D',
    warn: '#FF9900',
    info: '#187DDA',
  },
  disabled: {
    green: '#4F786F',
    grey: '#3C3C3C',
  },

  facebook: '#3B5998',
  linkedin: '#007AB9',
  twitter: '#55ACEE',
};
